import { checkIsStringEmpty } from "../dataTypes/string";

/**
 * @param bgColour hexcode w/o "#" character
 * @param textColor hexcode w/o "#" character
 */
export const getDummyImageSrc = ({
    width,
    height,
    text = "Sample Image",
    bgColour = "cbcbcb",
    textColor = "6a6a6a",
}: {
    width: number;
    height: number;
    text?: string;
    bgColour?: string;
    textColor?: string;
}) => {
    return `https://dummyimage.com/${width}x${height}/${bgColour}/${textColor}&text=${encodeURIComponent(
        text
    )}`;
};

/**
 * This will generate a letter favicon with exly's design language by as default
 */
export const getAvatarIcon = ({
    text = "",
    bgColour = "6c5dd3",
    textColor = "fff",
    font = "noto",
    fontSize = "28",
}: {
    text?: string;
    bgColour?: string;
    textColor?: string;
    font?: string;
    fontSize?: string;
}) =>
    checkIsStringEmpty(text)
        ? undefined
        : `https://fakeimg.pl/32/${bgColour}/${textColor}/?text=${text[0]}&font=${font}&font_size=${fontSize}`;

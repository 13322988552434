// @ts-ignore
import { getOptimisedFaviconUrl as _getOptimisedFaviconUrl } from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/ExlyImage/utils/getOptimisedFaviconUrl";
import { getOptimisedFaviconUrl as getOptimisedFaviconUrlType } from "@my-scoot/exly-react-component-lib/build/types/features/Common/modules/ExlyImage/utils/getOptimisedFaviconUrl";
import { IMAGE_OPTIMIZATION_CDN_URL } from "../constants/ExlyImage.constants";

const getOptimisedFaviconUrlBase: typeof getOptimisedFaviconUrlType =
    _getOptimisedFaviconUrl;

const getOptimisedFaviconUrl = (imageUrl: string) =>
    getOptimisedFaviconUrlBase(imageUrl, IMAGE_OPTIMIZATION_CDN_URL);

export { getOptimisedFaviconUrl };

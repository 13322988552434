import { getOptimisedFaviconUrl } from "repoV2/features/Common/modules/ExlyImage/utils/favicons";
import { DEFAULT_PROFILE_PIC_SRC } from "repoV2/utils/common/assets/assets";
import { getAvatarIcon } from "repoV2/utils/common/render/images";

export const getFaviconUrl = ({
    pageSpecificFavIcon,
    creatorSiteFaviconImgSrc,
    profileImgSrc,
    hostName,
}: {
    pageSpecificFavIcon?: string;
    creatorSiteFaviconImgSrc?: string;
    profileImgSrc?: string;
    hostName?: string;
}) =>
    getOptimisedFaviconUrl(
        pageSpecificFavIcon ||
            creatorSiteFaviconImgSrc ||
            profileImgSrc ||
            getAvatarIcon({ text: hostName }) ||
            DEFAULT_PROFILE_PIC_SRC
    );

import Script from "next/script";
import React, { FC } from "react";

const GTMScript: FC<{ accessToken?: string }> = ({ accessToken }) => {
    return accessToken ? (
        <>
            <Script
                strategy="afterInteractive"
                defer
                src={`https://www.googletagmanager.com/gtag/js?id="${accessToken}"`}
            />
            <Script
                strategy="afterInteractive"
                defer
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', "${accessToken}");
      `,
                }}
            />
        </>
    ) : null;
};

export default GTMScript;
